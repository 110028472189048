import type { defineComponent } from "vue";
import { computed, inject, provide } from "vue";
import { useI18n } from "vue-i18n";
import { TaskInside, TaskPriority, TaskStatus, VModernSelect } from "@verbleif/lib";
import { usePriority } from "@/features/Priority/usePriority";
import { useInside } from "@/features/Inside/useInside";
import { BLOCKINGS, useBlocking } from "@/features/Blocking/useBlocking";
import { useStatus } from "@/features/Status/useStatus";

const FiltersKey = Symbol("filters");

export enum FilterCondition {
  GREATER_THEN = "gt",
  GREATER_THEN_OR_EQUAL = "gte",
  LOWER_THEN = "lt",
  LOWER_THEN_OR_EQUAL = "lte",
  EQUAL = "eq",
  NOT_EQUAL = "neq",
  CONTAINS = "mbof",
  NOT_CONTAINS = "nmbof",
  EMPTY = "empty",
  NOT_EMPTY = "nempty",
  NULL = "null",
  NOT_NULL = "nnull",
}

export interface FilterItemPreset {
  leftHandKey: string | null
  leftHandLabel: string | null
  conditions: FilterCondition[]
  rightHandComponent?: ReturnType<typeof defineComponent>
  rightHandComponentInitialValue?: null | string
  rightHandComponentProps?: {
    [key: string]: any
  }
}

export interface FilterItem {
  leftHand: string | null
  condition: FilterCondition | null
  rightHand?: string | null | undefined
}

function createFilters() {
  const state = reactive<Record<"filters", FilterItem[]>>({
    filters: [],
  });

  const { t } = useI18n();
  const { statusToText } = useStatus();
  const { priorityToText } = usePriority();
  const { insideToText } = useInside();
  const { blockingToText } = useBlocking();
  const { loadSorts, loadGroups, loadObjects, loadUsers, loadDepartments } = useLoadAutocompleteSelects();

  const filters = computed(() => {
    return state.filters.filter((item) => {
      if (!item.leftHand) {
        return false;
      }

      if (!item.condition) {
        return false;
      }

      console.log("hasRightHand", !!item?.rightHand);
      if (conditionHasRightHand(item.condition)) {
        return !!item?.rightHand;
      }

      return true;
    });
  });

  function map(items: any[], transformer: any): Array<Record<"name" | "value", string>> {
    const res = [];

    for (const item of items) {
      res.push({
        name: t(transformer(item)),
        value: item,
      });
    }

    return res;
  }

  const conditionLabels = {
    [FilterCondition.EQUAL]: t("tasks.filters.equal"),
    [FilterCondition.NOT_EQUAL]: t("tasks.filters.not_equal"),
    [FilterCondition.CONTAINS]: t("tasks.filters.contains"),
    [FilterCondition.NOT_CONTAINS]: t("tasks.filters.not_contains"),
    [FilterCondition.EMPTY]: t("tasks.filters.empty"),
    [FilterCondition.NOT_EMPTY]: t("tasks.filters.not_empty"),
    [FilterCondition.NULL]: t("tasks.filters.null"),
    [FilterCondition.NOT_NULL]: t("tasks.filters.not_null"),
    [FilterCondition.GREATER_THEN]: t("tasks.filters.greater_then"),
    [FilterCondition.GREATER_THEN_OR_EQUAL]: t("tasks.filters.greater_then_or_equal"),
    [FilterCondition.LOWER_THEN]: t("tasks.filters.lower_then"),
    [FilterCondition.LOWER_THEN_OR_EQUAL]: t("tasks.filters.lower_then_or_equal"),
  };

  const filterItemPresets: FilterItemPreset[] = [
    {
      leftHandKey: "sort",
      leftHandLabel: t("tasks.sort_label"),
      conditions: [FilterCondition.EQUAL, FilterCondition.NOT_EQUAL],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        placeholder: t("tasks.sort_placeholder"),
        onLoad: loadSorts,
        keyFieldName: "id",
      },
    },
    {
      leftHandKey: "object.group",
      leftHandLabel: t("tasks.group_label"),
      conditions: [FilterCondition.EQUAL, FilterCondition.NOT_EQUAL, FilterCondition.NULL, FilterCondition.NOT_NULL],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        placeholder: t("tasks.group_placeholder"),
        onLoad: loadGroups,
        keyFieldName: "id",
      },
    },
    {
      leftHandKey: "object",
      leftHandLabel: t("tasks.object_label"),
      conditions: [FilterCondition.EQUAL, FilterCondition.NOT_EQUAL, FilterCondition.NULL, FilterCondition.NOT_NULL],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        placeholder: t("tasks.object_placeholder"),
        onLoad: loadObjects,
        keyFieldName: "id",
      },
    },
    {
      leftHandKey: "status",
      leftHandLabel: t("tasks.status_label"),
      conditions: [FilterCondition.EQUAL, FilterCondition.NOT_EQUAL],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        keyFieldName: "value",
        placeholder: t("tasks.status_placeholder"),
        items: map(Object.values(TaskStatus).filter(value => typeof value === "number") as number[], statusToText),
      },
    },
    {
      leftHandKey: "priority",
      leftHandLabel: t("tasks.priority_label"),
      conditions: [FilterCondition.EQUAL, FilterCondition.NOT_EQUAL, FilterCondition.NULL, FilterCondition.NOT_NULL],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        keyFieldName: "value",
        placeholder: t("tasks.priority_placeholder"),
        items: map(Object.values(TaskPriority).filter(value => typeof value === "number") as number[], priorityToText),
      },
    },
    {
      leftHandKey: "inside",
      leftHandLabel: t("tasks.inside_label"),
      conditions: [FilterCondition.EQUAL, FilterCondition.NOT_EQUAL, FilterCondition.NULL, FilterCondition.NOT_NULL],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        keyFieldName: "value",
        placeholder: t("tasks.inside_placeholder"),
        items: map(Object.values(TaskInside).filter(value => typeof value === "number") as number[], insideToText),
      },
    },
    {
      leftHandKey: "blocking",
      leftHandLabel: t("tasks.blocking_label"),
      conditions: [FilterCondition.EQUAL, FilterCondition.NOT_EQUAL, FilterCondition.NULL, FilterCondition.NOT_NULL],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        keyFieldName: "value",
        placeholder: t("tasks.blocking_placeholder"),
        items: map(BLOCKINGS, blockingToText),
      },
    },
    {
      leftHandKey: "users",
      leftHandLabel: t("tasks.filters.assigned_users"),
      conditions: [
        FilterCondition.EMPTY,
        FilterCondition.NOT_EMPTY,
        FilterCondition.CONTAINS,
        FilterCondition.NOT_CONTAINS,
      ],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        placeholder: t("tasks.filters.assigned_users_placeholder"),
        onLoad: loadUsers,
        searchFieldName: "username",
        keyFieldName: "id",
      },
    },
    {
      leftHandKey: "departments",
      leftHandLabel: t("tasks.filters.assigned_departments"),
      conditions: [
        FilterCondition.EMPTY,
        FilterCondition.NOT_EMPTY,
        FilterCondition.CONTAINS,
        FilterCondition.NOT_CONTAINS,
      ],
      rightHandComponent: VModernSelect,
      rightHandComponentProps: {
        placeholder: t("tasks.filters.assigned_departments_placeholder"),
        onLoad: loadDepartments,
        keyFieldName: "id",
      },
    },
  ];

  function conditionHasRightHand(condition: FilterCondition) {
    return ![
      FilterCondition.EMPTY,
      FilterCondition.NOT_EMPTY,
      FilterCondition.NULL,
      FilterCondition.NOT_NULL,
    ].includes(condition);
  }

  function removeFilter(index: number) {
    state.filters.splice(index, 1);
  }

  function onUpdateLeftHand(index: number, leftHand: string | null) {
    state.filters[index].condition = null;

    if (!leftHand) {
      state.filters[index].rightHand = undefined;
      return;
    }

    const preset = filterItemPresets.find(item => item.leftHandKey === leftHand);

    let initialValue = null;
    if (preset && "rightHandComponentInitialValue" in preset) {
      initialValue = preset.rightHandComponentInitialValue;
    }

    state.filters[index].rightHand = initialValue;
  }

  function getPresetByLeftHandKey(leftHandKey: string): FilterItemPreset {
    const preset = filterItemPresets.find(item => item.leftHandKey === leftHandKey);

    if (!preset) {
      throw new Error(`No preset found for leftHandKey: ${leftHandKey}`);
    }

    return preset;
  }

  function getConditionValuesByLeftHandKey(leftHandKey: string) {
    const preset = getPresetByLeftHandKey(leftHandKey);

    return preset.conditions.map((condition) => {
      return {
        value: condition,
        name: conditionLabels[condition],
      };
    });
  }

  function addFilter() {
    state.filters.push({
      leftHand: null,
      condition: null,
      rightHand: undefined,
    });
  }

  function getParams(params: URLSearchParams) {
    if (filters.value.length === 0) {
      return;
    }

    // First disable all already set params, this must be separate from the next loop because you can have multiple filters for the same key.
    for (const filter of filters.value) {
      if (!filter.leftHand) {
        continue;
      }

      params.forEach((_, k) => {
        if (
          k.startsWith(`filter[where][${filter.leftHand}]`)
          || k.startsWith(`filter[where][and][${filter.leftHand}]`)
          || k.startsWith(`filter[where][or][${filter.leftHand}]`)
        ) {
          params.delete(k);
        }
      });
    }

    for (const filter of filters.value) {
      if (!filter.leftHand || !filter.condition) {
        continue;
      }

      if (conditionHasRightHand(filter.condition) && filter.rightHand) {
        params.set(`filter[where][${filter.leftHand}][${filter.condition}]`, `${filter.rightHand}`);

        continue;
      }

      params.set(`filter[where][${filter.leftHand}][${filter.condition}]`, "");
    }
  }

  function resetFilters() {
    state.filters = [];
  }

  const countFilters = computed(() => {
    return filters.value.length;
  });

  const isFilterActive = computed(() => {
    return !!countFilters.value;
  });

  return {
    getParams,
    getConditionValuesByLeftHandKey,
    getPresetByLeftHandKey,
    onUpdateLeftHand,
    filters,
    state,
    filterItemPresets,
    isFilterActive,
    resetFilters,
    countFilters,
    removeFilter,
    addFilter,
    conditionHasRightHand,
  };
}

export function provideFilters() {
  const instance = createFilters();
  provide(FiltersKey, instance);
  return instance;
}

type UseFilter = ReturnType<typeof createFilters>;
export function useFilters() {
  const instance = inject<UseFilter>(FiltersKey);

  if (!instance) {
    throw new Error("Run provideFilters before useFilters.");
  }

  return instance;
}
