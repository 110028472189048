import { useLocationStore } from "@verbleif/shared";
import api from "@/core/api";

export function useLoadAutocompleteSelects() {
  const locationStore = useLocationStore();

  const defaultParams: Record<string, string> = {
    "active": "1",
    "order[name]": "ASC",
  };

  function appendDefaultParams(options: any) {
    for (const key in defaultParams) {
      if (!Object.prototype.hasOwnProperty.call(defaultParams, key)) {
        continue;
      }
      options.params.append(key, defaultParams[key]);
    }
    return options;
  }

  const locationIri = locationStore.selectedLocation.value;
  const locationIriV2 = `/api/locations/${locationStore.getSelectedLocationId.value}`;

  function loadSorts(options: any) {
    return api.get(`${locationIri}/sorts`, appendDefaultParams(options));
  }

  function loadObjects(options: any) {
    return api.get(`${locationIri}/objects`, appendDefaultParams(options));
  }

  function loadSpaces(options: any) {
    return api.get(`${locationIri}/spaces`, appendDefaultParams(options));
  }

  function loadMeans(options: any) {
    return api.get(`${locationIri}/means`, appendDefaultParams(options));
  }

  function loadGroups(options: any) {
    return api.get(`${locationIri}/groups`, appendDefaultParams(options));
  }

  function loadDepartments(options: any) {
    return api.get(`${locationIri}/departments`, appendDefaultParams(options));
  }

  function loadTeams(options: any) {
    return api.get(`${locationIriV2}/teams`, appendDefaultParams(options));
  }

  function loadUsers(options: any) {
    return api.get(`${locationIri}/users`, appendDefaultParams(options));
  }

  function loadLocations(options: any) {
    return api.get("/locations", appendDefaultParams(options));
  }

  return {
    loadLocations,
    loadUsers,
    loadSorts,
    loadObjects,
    loadSpaces,
    loadMeans,
    loadGroups,
    loadDepartments,
    loadTeams,
  };
}
