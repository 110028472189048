<script setup>
import { useI18n } from "vue-i18n";
import { Feature, UserRole, useLocationStore } from "@verbleif/shared";
import { useRightsStore } from "@/core/store/RightStore";
import SidebarItem from "@/features/Settings/SidebarItem.vue";

const { t } = useI18n();
const { hasFeature } = useRightsStore();
const { hasRole } = useRightsStore();
const locationStore = useLocationStore();
</script>

<template>
  <div class="settings-container">
    <div class="sidebar">
      <div class="title">
        {{ t("settings.sidebar.title") }}
      </div>
      <div class="section">
        <div class="title">
          {{ t("settings.sidebar.account") }}
        </div>
        <SidebarItem
          :to="{ name: 'my-account' }"
          :text="t('settings.sidebar.my_account')"
          :icon="['far', 'user-shield']"
        />
        <SidebarItem
          v-if="hasFeature(Feature.SUBSCRIPTION_MODULE) && hasRole(UserRole.ROLE_PARK_MANAGER)"
          :to="{ name: 'subscription' }"
          :text="t('settings.sidebar.subscription')"
          :icon="['far', 'credit-card']"
        />
        <SidebarItem
          v-if="hasFeature(Feature.SUBSCRIPTION_MODULE) && hasRole(UserRole.ROLE_PARK_MANAGER)"
          :to="{ name: 'invoices' }"
          :text="t('settings.sidebar.invoices')"
          :icon="['far', 'file-invoice']"
        />
      </div>
      <div
        v-if="hasRole(UserRole.ROLE_PARK_MANAGER) && hasFeature(Feature.LOCATION_MODULE)"
        class="section"
      >
        <div class="title">
          {{ t("settings.sidebar.global") }}
        </div>
        <SidebarItem
          :to="{ name: 'locations' }"
          :text="t('settings.sidebar.locations')"
          :icon="['far', 'location']"
        />
        <SidebarItem
          :to="{ name: 'users' }"
          :text="t('settings.sidebar.global_users')"
          :icon="['far', 'user-friends']"
        />
      </div>
      <div v-if="hasRole(UserRole.ROLE_DEPARTMENT_MANAGER)" class="section">
        <div class="title">
          {{ t("settings.sidebar.location_specific_user", { locationName: locationStore.selectedLocationObject.value?.name }) }}
        </div>
        <SidebarItem
          v-if="hasRole(UserRole.ROLE_PARK_MANAGER)"
          :to="{ name: 'departments' }"
          :text="t('settings.sidebar.departments')"
          :icon="['far', 'users-class']"
        />
        <SidebarItem
          :to="{ name: 'location-users' }"
          :text="t('settings.sidebar.users')"
          :icon="['far', 'user-friends']"
        />
        <!--        <SidebarItem -->
        <!--          v-if="hasFeature(Feature.ROLES_MODULE)" -->
        <!--          :to="{ name: 'roles' }" -->
        <!--          :text="t('settings.sidebar.roles')" -->
        <!--          :icon="['far', 'lock']" -->
        <!--        /> -->
      </div>
      <div v-if="hasRole(UserRole.ROLE_PARK_MANAGER)" class="section">
        <div class="title">
          {{ t("settings.sidebar.tasksAndReports", { locationName: locationStore.selectedLocationObject.value?.name }) }}
        </div>
        <SidebarItem
          :to="{ name: 'sorts' }"
          :text="t('settings.sidebar.sorts')"
          :icon="['far', 'random']"
        />
      </div>
      <div v-if="hasRole(UserRole.ROLE_PARK_MANAGER)" class="section">
        <div class="title">
          {{ t("settings.sidebar.system", { locationName: locationStore.selectedLocationObject.value?.name }) }}
        </div>
        <SidebarItem
          :to="{ name: 'groups' }"
          :text="t('settings.sidebar.groups')"
          :icon="['far', 'th']"
        />
        <SidebarItem
          :to="{ name: 'objects' }"
          :text="t('settings.sidebar.objects')"
          :icon="['far', 'home']"
        />
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" src="./Settings.scss" scoped></style>

<style lang="scss" scoped>
.settings-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  &::v-deep(.header) {
    .title {
      font-weight: bold;
      font-size: 22px;
    }
  }

  .content {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 18px;
    overflow-y: auto;
  }
  .sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    background-color: var(--background-dark);
    padding-top: 18px;

    .title {
      margin-left: 18px;
      color: var(--text);
      font-weight: bold;
    }
    > .title {
      font-size: 25px;
    }
    .section {
      margin-top: 25px;

      .title {
        font-size: 11px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
